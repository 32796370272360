.container{
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: 50px;
    margin-right: 50px;
}

.container h1{
    margin-bottom: 50px;
}

.list__wrapper{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;
    flex-direction: row;
}

.list__wrapper ul{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    min-width: 400px;
}