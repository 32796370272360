.dashboard {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.dashboard_menu{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.dashboard_sub_menu {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.dashboard_menu h2{
  margin: 30px;
}

.dashboard_menu h3{
  margin: 10px;
  cursor: pointer;
}

.dashboard_menu h5 a{
  margin: 10px;
  text-decoration: none;
}

  .dashboard__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
  }
  .dashboard__btn {
    padding: 10px;
    font-size: 18px;
    margin-top: 10px;
    border: none;
    color: white;
    background-color: black;
  }
  .dashboard div {
    margin-top: 7px;
  }
  .add_project_container{
    display: flex;
    flex-direction: column;
  }