.upload{
  max-width: 900px;
  height: 200px;
  width: 100%;
  border: 1px solid #ddd;
  padding: 15px;
  position: relative;
}
#file_up{
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
}
#file_up::before{
  content: "+";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  color: #000;
  font-size: 7rem;
  text-align: center;
  cursor: pointer;
  margin: auto;
}
#file_img{
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left: 0;
  background: white;
}
#file_img img{
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
#file_img span{
  position: absolute;
  top: -13px;
  right: -13px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 6px 10px;
  cursor: pointer;
  font-weight: 900;
  color: crimson;
}