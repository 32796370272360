.details{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 50px;
    font-size: 150%;
}
.details img{
    max-width: 500px;
    width: 100%;
    margin: 20px;
    height: 500px;
    object-fit: cover;
    display: block;
}
.box-detail{
    max-width: 500px;
    width: 100%;
    margin: 50px 20px;
}
.box-detail .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-detail h6{
    text-transform: uppercase;
    color: black;
    letter-spacing: 1px;
    font-weight: 2rem;
}
.box-detail p{
    line-height: 1;
    margin: 10px 0;
    opacity: 0.8;
    font-size: small;
}
.box-detail .cart{
    background: #333;
    color: white;
    margin-top: 10px;
    padding: 10px 25px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
}

/* React slick slider */


.details_slider > .slick-slider{
    width: 400px;
    height: 450px;
}

.custom-indicator{
    bottom: -50px;
}

.custom-indicator li {
    width: 50px;
    height: 50px;
}

/* .slick-dots {
    margin-top:5px;
    margin-left: -5px;
    margin-right: -5px;
    display:flex;
    justify-content: center;
    margin-left: 100px;
}

.slick-dots li{
    list-style: none;
    display: inline-block;
    max-width: 112px;
    max-height: 56px;
    margin: 5px;
}
.slick-dots img{
    opacity: 0.5;
      width: 80%;
      height: auto;
      margin-left: 100px;
} */
