.footer-container{
    background-color: #ffffff;
}

.footer-wrapper > .menu-footer > h5{
    font-size: 18px;
    font-weight: 700;
    color: #000;
}


.footer-wrapper > .menu-footer > a{
    color: #000;
    text-decoration: none;
    font-size: 15px;
    font-weight: 300;
}


.MuiBox-root.css-54x2ta{
    margin-top: 100px;
    margin-bottom: 20px;
}    
